<template>
    <div>
        <label v-if="label" :for="id" class="mb-1 block text-sm font-medium text-gray-300">{{
            label
        }}</label>
        <div :class="error ? 'relative rounded-md shadow-sm' : null">
            <input
                :type="type"
                :id="id"
                :name="name"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                :class="
                    error
                        ? 'pr-10 text-red-400 placeholder-red-900 focus:ring-red-500'
                        : 'text-white placeholder-gray-600 focus:ring-indigo-500'
                "
                :placeholder="placeholder"
                :required="required"
                :readonly="readonly"
                :min="min"
                :max="max"
                :step="step"
                :autocomplete="autocomplete"
                :aria-describedby="
                    error ? `${name}-error` : help || slots.help ? `${name}-description` : null
                "
                v-bind="inputAttrs"
            />
            <div
                v-if="error"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <ExclamationCircleIcon class="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
        </div>
        <p class="mt-2 text-sm text-gray-500" v-if="help || slots.help" :id="`${name}-description`">
            <slot v-if="slots.help" name="help"></slot>
            <template v-if="help">{{ help }}</template>
        </p>
        <p
            class="text-sm text-red-600"
            :class="!help && !slots.help ? 'mt-2' : null"
            v-if="error"
            :id="`${name}-error`"
        >
            {{ error }}
        </p>
    </div>
</template>

<script setup>
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import random from 'lodash/random'
import { toRef, useSlots } from 'vue'

const props = defineProps({
    modelValue: {
        required: false,
    },
    id: {
        type: String,
        required: false,
    },
    name: {
        type: String,
        required: false,
    },
    type: {
        type: String,
        required: false,
        default: 'text',
    },
    label: {
        type: String,
        required: false,
    },
    placeholder: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
    },
    readonly: {
        type: Boolean,
        required: false,
    },
    min: {
        required: false,
    },
    max: {
        required: false,
    },
    step: {
        required: false,
    },
    autocomplete: {
        required: false,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
        required: false,
    },
    inputAttrs: {
        type: Object,
        required: false,
    },
})

const randomId = random(999999999).toString(36)

const id = props.id ? toRef(props, 'id') : randomId
const name = props.name ? toRef(props, 'name') : randomId
const type = toRef(props, 'type')
const label = toRef(props, 'label')
const placeholder = toRef(props, 'placeholder')
const required = toRef(props, 'required')
const readonly = toRef(props, 'readonly')
const min = toRef(props, 'min')
const max = toRef(props, 'max')
const step = toRef(props, 'step')
const autocomplete = toRef(props, 'autocomplete')
const help = toRef(props, 'help')
const error = toRef(props, 'error')
const inputAttrs = toRef(props, 'inputAttrs')

const slots = useSlots()
</script>
